/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react'
import Swal from 'sweetalert2'
// import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import { Button } from 'react-bootstrap-v5'
import { KTSVG } from '../../_metronic/helpers'
import showLoader from "../helpers/ShowLoader"
import { Modal } from 'react-bootstrap'
// import Spinner from 'react-bootstrap/Spinner'
import { AppContext } from '../context/AppContext'
import axios from 'axios'



function UserCreate({ show, handleClose, id }) {

  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [role, setRole] = useState('')
  const [status, setStatus] = useState('active')
  const [address, setAddress] = useState('')
  const [roleOptions, setRoleOptions] = useState([])
  // const [isSubmitDisable, setSubmitDisable] = useState(false);

  const myRef = useRef()

  const { token } = useContext(AppContext);
  const [file, setFile] = useState('');

  useEffect(() => {
    if (id) {
      getUserDetails(id)
    }
  }, [id])

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'get_all_roles', getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        setRoleOptions(resp)
      })
      .catch((error) => {
        console.log(error, 'catch the hoop')
      })
  }, [])

  const getUserDetails = (userId) => {

    showLoader()
    fetch(process.env.REACT_APP_API_URL + 'user_details/' + userId, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        Swal.close();
        setFirstName(resp.firstName)
        setLastName(resp.lastName)
        setEmail(resp.email)
        setPassword('')
        setConfirmPassword('')
        setPhoneNumber(resp.phoneNumber)
        setRole(resp.idRoles)
        setStatus(resp.status)
        setAddress(resp.address)
      })
      .catch((error) => {
        Swal.close();
        console.log(error, 'catch the hoop')
      })

  }

  const statusType = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ]

  const handleFileChange = (e) => {

    if (e.target.files.length < 1) {
      return;
    }
    if (isValidFileUploaded(e.target.files[0])) {
      // setImage(URL.createObjectURL(e.target.files[0]));
      setFile(e.target.files[0]);
    } else {
      alert('File type not supported');
      setFile('');
    }
  }

  const isValidFileUploaded = (file) => {
    const validExtensions = ['png', 'jpeg', 'jpg']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
  }


  const handleSubmit = (evt) => {

    evt.preventDefault()
    setIsLoading(true)

    const formData = new FormData();
    formData.append('id', id);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('password_confirmation', confirmPassword);
    formData.append('phoneNumber', phoneNumber);
    formData.append('role', role);
    formData.append('status', status);
    formData.append('address', address);
    formData.append('profile_picture', file);

    const jwt = token;
    if (id !== '') {
      var apiEnd = 'user_update'
    } else {
      apiEnd = 'user_create'
    }

    var authOptions = {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt },
      url: process.env.REACT_APP_API_URL + apiEnd,
      data: formData
    };

    // setSubmitDisable(true);

    try {

      axios(authOptions)
        .then(response => {

          console.log(response)
          // const successMessage = response.data.successMessage;
          // Swal.fire({
          //   icon: 'success',
          //   title: 'Success',
          //   text: successMessage
          // })
          // setProjectData(initialState);
          // handleClose(response)

          if (id !== '') {
            var successMsg = [`User ${response.data.data.email} updated`]
          } else {
            successMsg = [`User ${response.data.data.email} added`]
          }

          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: successMsg,
          })
          setFirstName('')
          setLastName('')
          setEmail('')
          setPassword('')
          setConfirmPassword('')
          setPhoneNumber('')
          setRole('')
          setAddress('')
          setStatus('active')
          setFile('')
          // props.onCreate({ id: resp.data.id, name: resp.data.email })
          handleClose(response.data.success)
          setIsLoading(false)
        })
        .catch((error) => {
          const errorsMessage = validationErrorMsg(error.response.data.errors).join("<br>");
          Swal.fire({
            icon: 'error',
            title: 'Error',
            html: errorsMessage,
          })
          setIsLoading(false)
        });




      // handleClose(resp.success)

    } catch (error) {
      setIsLoading(false)
      console.log(error, 'catch the hoop')
      // setSubmitDisable(false);
    }

    // fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
    //   .then((resp) => {
    //     return resp.json()
    //   })
    //   .then((resp) => {
    //     // console.log(resp)
    //     setIsLoading(false)
    //     // setSubmitDisable(false);
    //     if (resp.success === true) {
    //       if (id !== '') {
    //         var successMsg = [`User ${resp.data.email} updated`]
    //       } else {
    //         successMsg = [`User ${resp.data.email} added`]
    //       }

    //       Swal.fire({
    //         icon: 'success',
    //         title: 'Success',
    //         text: successMsg,
    //       })
    //       setFirstName('')
    //       setLastName('')
    //       setEmail('')
    //       setPassword('')
    //       setConfirmPassword('')
    //       setPhoneNumber('')
    //       setRole('')
    //       setAddress('')
    //       setStatus('active')
    //       // props.onCreate({ id: resp.data.id, name: resp.data.email })
    //       handleClose(resp.success)

    //     } else {
    //       const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
    //       Swal.fire({
    //         icon: 'error',
    //         title: 'Error',
    //         html: errorsMessage,
    //       })
    //     }
    //   })
    //   .catch((error) => {
    //     setIsLoading(false)
    //     console.log(error, 'catch the hoop')
    //     // setSubmitDisable(false);
    //   })
  }

  // const handleCancel = () => {
  //   setFirstName('')
  //   setLastName('')
  //   setEmail('')
  //   setPassword('')
  //   setConfirmPassword('')
  //   setPhoneNumber('')
  //   setRole('')
  //   setAddress('')
  //   setStatus('active')
  // }

  return (

    <Modal show={show} onHide={() => handleClose(false)} size="lg">
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{id !== '' ? 'Edit' : 'Create'} User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {JSON.stringify(firstName, null, 2)} */}
          {/* {JSON.stringify(status, null, 2)} */}
          <div className='form-group row'>
            <label htmlFor='role' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
              Role<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <select
                className='form-select form-select-sm'
                id='role'
                name='role'
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value=''>Select Role</option>
                {roleOptions?.map(function (item, key) {
                  return <option key={'role-' + key} value={item.id}>{item.name}</option>
                })}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='firstName'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              First Name<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='firstName'
                id='firstName'
                ref={myRef}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='lastName'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Last Name<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='lastName'
                id='lastName'
                ref={myRef}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label htmlFor='email' className='col-lg-3 col-form-label' style={{ textAlign: 'right' }}>
              Email<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <input
                type='email'
                name='email'
                id='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={id !== ''}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='password'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Password {id === '' && <span className='required text-danger'></span>}
            </label>
            <div className='col-lg-5'>
              <input
                type='password'
                name='password'
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='confirmPassword'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Confirm Password{id === '' && <span className='required text-danger'></span>}
            </label>
            <div className='col-lg-5'>
              <input
                type='password'
                name='confirmPassword'
                id='confirmPassword'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='phoneNumber'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Phone Number
            </label>
            <div className='col-lg-5'>
              <input
                type='text'
                name='phoneNumber'
                id='phoneNumber'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='profilePicture'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Profile Picture
            </label>
            <div className='col-lg-5'>
              <input
                type='file'
                name='profilePicture'
                id='profilePicture'
                // value={file}
                onChange={(e) => handleFileChange(e)}
                className='form-control form-control-sm'
              />
            </div>
          </div>

          <div className='form-group row'>
            <label
              htmlFor='status'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Status<span className='required text-danger'></span>
            </label>
            <div className='col-lg-5'>
              <select
                className='form-select form-select-sm'
                id='status'
                name='status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                {statusType.map(function (item, id) {
                  return (
                    <option key={id} value={item.value}>
                      {item.label}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='form-group row'>
            <label
              htmlFor='address'
              className='col-lg-3 col-form-label'
              style={{ textAlign: 'right' }}
            >
              Address
            </label>
            <div className='col-lg-5'>
              <textarea
                name='address'
                id='address'
                onChange={(e) => setAddress(e.target.value)}
                className='form-control form-control-sm'
                value={address}
              >{address}</textarea>
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer>

          <Button disabled={isLoading} variant='success' size='sm' type='submit'>
            {isLoading ? (
              <i className='fa fa-spin fa-spinner'></i>
            ) : (
              <>
                <KTSVG className='svg-icon-3' path='/media/svg/icons/General/Save.svg' />{' '}
                {id !== '' ? 'Update' : 'Save'}
              </>
            )}
          </Button>

          <Button
            type='button'
            variant='secondary'
            size='sm'
            onClick={() => handleClose(false)}
            className='me-2'
          >
            <KTSVG className='svg-icon-3' path='/media/svg/icons/Navigation/Close.svg' /> Close
          </Button>

        </Modal.Footer>
      </form>
    </Modal>



  )
}

export default UserCreate
