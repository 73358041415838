/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext, ChangeEvent } from 'react'
import { postRequestOptions, getRequestOptions } from "../helpers/Fetchwrapper";
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import { useParams } from "react-router-dom"
import { iconArray } from '../components/LeadActivities';
import { KTSVG } from '../../_metronic/helpers'
import React from 'react';
import { AppContext } from '../context/AppContext'
import { Link } from "react-router-dom"

interface Lead {
    leadName: string;
    companyName: number;
    lastActivity: string;
    [key: string]: any;
}

interface User {
    idUsers: number;
    stats: {
        target: number;
        incentive: number;
        conversion: number;
        leadsAssigned: number;
        totalReached: number;
        totalCall: number;
        totalMeeting: number;
        totalVisit: number;
        salesOrderAmount: number;
        salesOrderQuantity: number;
        paymentAmount: number;
        invoiceAmount: number;
    };
    user: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        address: string;
        profilePicture: string;
        projectName: string;
        idProjects: number;
    };
    leads: Lead[];
    activities: any;
    collection: any;
}

interface RouteParams {
    id: string;
}

interface FormDataType {
    fromDate?: string,
    toDate?: string,
}

export function SrDetails(props: any) {

    const { currentUser } = useContext(AppContext);

    const convertToBDAmount = (label: any) => {
        let labelVal = Number(label);

        if (label < 0) {
            labelVal = label * -1;
        }

        if (labelVal > 10000000) {
            labelVal = (labelVal / 10000000).toFixed(1) + ' Cr';
        }
        if (labelVal > 100000) {
            labelVal = (labelVal / 100000).toFixed(1) + ' Lac';
        }
        if (labelVal > 1000) {
            labelVal = (labelVal / 1000).toFixed(1) + ' K';
        }
        if (label < 0) {
            return '-' + labelVal;
        }
        return labelVal;
    }

    function getFirstAndLastDateOfCurrentYear() {
        // Get the current year
        let currentYear = new Date().getFullYear();

        // First date of the current year
        let firstDate = new Date(currentYear, 0, 1);

        // Last date of the current year
        let lastDate = new Date(currentYear, 11, 31);

        // Format the dates as Y-m-d
        let formatDate = (date: any) => {
            let yearPart = date.getFullYear();
            let monthPart = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            let dayPart = String(date.getDate()).padStart(2, '0');
            return `${yearPart}-${monthPart}-${dayPart}`;
        };

        return {
            firstDate: formatDate(firstDate),
            lastDate: formatDate(lastDate)
        };
    }

    // Example usage:
    let dates = getFirstAndLastDateOfCurrentYear();

    const initialState: FormDataType = {
        fromDate: dates.firstDate,
        toDate: dates.lastDate,
    };

    function getFirstAndLastDateOfCurrentMonth() {
        // Get the current date
        let currentDate = new Date();

        // First date of the current month
        let firstDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        // Last date of the current month
        let lastDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        // Format the dates as Y-m-d
        let formatDate = (date: any) => {
            let yearPart = date.getFullYear();
            let monthPart = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            let dayPart = String(date.getDate()).padStart(2, '0');
            return `${yearPart}-${monthPart}-${dayPart}`;
        };

        return {
            firstDate: formatDate(firstDate),
            lastDate: formatDate(lastDate)
        };
    }

    const [userInfo, setUserInfo] = useState<User | null>(null);
    const [activityType, setActivityType] = useState('');
    const [dateRange, setDateRange] = useState('year');
    const { id = currentUser.id }: RouteParams = useParams();
    const [formData, setFormData] = useState<FormDataType>(initialState);
    const [customDate, setCustomDate] = useState({ customFromDate: '', customToDate: '' });

    const handleClickActivity = (param: string) => {
        setActivityType(param);
    };

    const handleDateRangeChange = (param: string) => {
        setDateRange(param)
        if (param === 'year') {
            let dates = getFirstAndLastDateOfCurrentYear();
            setFormData({ ...formData, fromDate: dates.firstDate, toDate: dates.lastDate });
            generateOrderSheetReport(dates.firstDate, dates.lastDate, userInfo.idProjects)
            // generateCollectionSheetReport(dates.firstDate, dates.lastDate, userInfo.idProjects)
        } else if (param === 'month') {
            let dates = getFirstAndLastDateOfCurrentMonth();
            setFormData({ ...formData, fromDate: dates.firstDate, toDate: dates.lastDate });
            generateOrderSheetReport(dates.firstDate, dates.lastDate, userInfo.idProjects)
            // generateCollectionSheetReport(dates.firstDate, dates.lastDate, userInfo.idProjects)
        } else {

        }
    }

    const handleCustomDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        // setFormData({ ...formData, fromDate: dates.firstDate, toDate: dates.lastDate });
        setCustomDate({ ...customDate, [e.target.name]: e.target.value });
    };

    const showCustomDateData = () => {

        setFormData({ ...formData, fromDate: customDate.customFromDate, toDate: customDate.customToDate });
        generateOrderSheetReport(dates.firstDate, dates.lastDate, userInfo.idProjects)

    };

    const [orderSheetData, setOrderSheetData] = useState([]);
    const generateOrderSheetReport = async (fromDate: string, toDate: string, idProjects: number) => {

        let formData = {
            idProjects: '',
            idSr: id,
            fromDate: fromDate,
            toDate: toDate,
            idSrCategories: '',
            showRate: true
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'reports/order-sheet', postRequestOptions(formData));
            const data = await response.json();
            if (data.success) {
                setOrderSheetData(data.data);
            } else {
                setOrderSheetData([]);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    useEffect(() => {
        getUserInfo();
    }, [id, formData.fromDate, formData.toDate])

    const getUserInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'sr_details/' + id + '?fromDate=' + formData.fromDate + '&toDate=' + formData.toDate,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                setUserInfo(resp.data);
                generateOrderSheetReport(formData.fromDate, formData.toDate, resp.data.user.idProjects)
                // generateCollectionSheetReport(formData.fromDate, formData.toDate, resp.data.user.idProjects)
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }

        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return (<>


        <div style={{ background: "none" }} className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>SR details</h3>
                    <small className='text-muted'>| SR Leads & Activities</small>
                </div>
            </div>
            <div className="card-body pt-0">

                <div className='d-flex justify-content-end'>
                    <div className='mt-5 mb-2'>
                        <button className={`btn ${dateRange === 'year' ? 'btn-primary' : 'btn-light'}`} onClick={() => handleDateRangeChange('year')}>This Year</button>
                        <button className={`btn ${dateRange === 'month' ? 'btn-primary' : 'btn-light'}`} style={{ marginLeft: '15px' }} onClick={() => handleDateRangeChange('month')}>This Month</button>
                        <button className={`btn ${dateRange === 'custom' ? 'btn-primary' : 'btn-light'}`} style={{ marginLeft: '15px' }} onClick={() => handleDateRangeChange('custom')}>Custom</button>
                        {/* <select className='form-control' onChange={(event) => handleDateRangeChange(event)}>
                            <option value={'year'}>This Year</option>
                            <option value={'month'}>This Month</option>
                        </select> */}
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                    {/* <div className='mt-5 mb-2'>
                        <select className='form-control' onChange={(event) => handleDateRangeChange(event)}>
                            <option value={'year'}>This Year</option>
                            <option value={'month'}>This Month</option>
                            <option value={''}>Custom</option>
                        </select>
                    </div> */}
                    {(dateRange === 'custom') &&
                        <>
                            &nbsp;&nbsp;&nbsp;
                            <div className='mt-5 mb-2'>
                                <input type='date' className='form-control  form-control-sm' name='customFromDate' onChange={handleCustomDateChange}></input>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className='mt-5 mb-2'>
                                <input type='date' className='form-control  form-control-sm' name='customToDate' onChange={handleCustomDateChange}></input>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className='mt-5 mb-2'>
                                <button className='btn-primary form-control  form-control-sm' name='showCustomData' onClick={showCustomDateData}>Show</button>
                            </div>
                        </>
                    }
                    {/* {JSON.stringify(customDate, null, 2)} */}
                </div>


                <Row>
                    <Col md={3}></Col>
                    <Col md={6} className="pt-2">
                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Basic Info</span>
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                <Table striped bordered hover size='sm'>
                                    <tbody>
                                        <tr>
                                            <td rowSpan={5} className='text-center col-4'>
                                                {userInfo?.user?.profilePicture !== null ?
                                                    <img src={`${process.env.REACT_APP_AWS_URL}next-crm/profile_picture/${userInfo?.user?.profilePicture}`} alt="pic" style={{ width: "60px", height: "60px" }} />
                                                    :
                                                    <img src={`media/avatars/default.jpg`} alt="pic" style={{ width: "60px", height: "60px" }} />

                                                }
                                            </td>
                                            <td className='col-8'>{userInfo?.user.firstName + ' ' + userInfo?.user.lastName}</td>
                                        </tr>
                                        <tr>
                                            <td>Email: {userInfo?.user?.email}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone Number: {userInfo?.user?.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Address: {userInfo?.user?.address}</td>
                                        </tr>
                                        <tr>
                                            <td>Project: {userInfo?.user?.projectName}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                    <Col md={3}></Col>
                </Row>



                <div className="row gy-5 g-xl-2 mt-4 ml-4 d-flex justify-content-center align-items-center">
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('target')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/target.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(userInfo?.stats.target)}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Target </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('sales')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/sales.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(userInfo?.stats.salesOrderAmount)}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Sales </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('collection')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/sales.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(userInfo?.stats.paymentAmount)}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Collection </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('invoice')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/sales.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(userInfo?.stats.invoiceAmount)}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Invoiced </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('incentive')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/incentive.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">0</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Incentive </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('quantity')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/ranking.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{convertToBDAmount(userInfo?.stats.salesOrderQuantity)}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Qty </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('lead')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/lead.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{userInfo?.stats.leadsAssigned}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Lead </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('conversion')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/conversion.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{userInfo?.stats.conversion}%</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Conversion </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-2 mb-xl-10" style={{ width: '9.33%' }} onClick={() => handleClickActivity('visit')}>
                        <div className="card h-lg-100">
                            <div className="card-body card-lead d-flex justify-content-between align-items-center flex-column">
                                <div className="m-2">
                                    <img alt='icon' className='svg-icon-danger svg-icon svg-icon-2' width={70} height={70} src='/media/png/visit.png'></img>
                                </div>
                                <div className="d-flex flex-column my-4 text-center">
                                    <span className="fw-semibold fs-2x text-gray-800 lh-1 ls-n2">{userInfo?.stats.totalVisit}</span>
                                    <div className="my-1 text-center">
                                        <span className="fw-semibold fs-4 text-gray-400"> Visit </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {activityType === 'lead' &&
                    <div className={`card card-custom my-2`}>
                        <div className='card-header align-items-center border-0'>
                            <h3 className='card-title align-items-start flex-row'>
                                <span className='fw-bold mb-2 text-dark me-2'>Leads List</span>
                                <small className='mt-1'></small>
                            </h3>

                        </div>
                        <div className='card-body'>
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th>Lead</th>
                                        <th>Client</th>
                                        <th>Last Activity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo?.leads.length > 0 ? (
                                        userInfo?.leads.map((lead, slIndex) => (

                                            <tr key={lead.idLeads}>
                                                <td>{lead.leadName}</td>
                                                <td>{lead.companyName}</td>
                                                <td>{lead.lastActivity}</td>
                                            </tr>

                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3} className='text-center'>No Lead Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }

                {activityType === 'invoice' &&
                    <div className={`card card-custom my-2`}>
                        <div className='card-header align-items-center border-0'>
                            <h3 className='card-title align-items-start flex-row'>
                                <span className='fw-bold mb-2 text-dark me-2'>Invoice List</span>
                                <small className='mt-1'></small>
                            </h3>

                        </div>
                        <div className='card-body'>
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>Invoice ID</th>
                                        <th>Invoice Date</th>
                                        <th>Sales Order ID</th>
                                        <th>Invoice Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo?.invoices.length > 0 ? (
                                        userInfo?.invoices.map((invoice, siIndex) => (

                                            <tr key={invoice.idSalesInvoice}>
                                                <td>{invoice.companyName}</td>
                                                <td><Link to={`/sales-invoice/${invoice.idSalesInvoice}`}>{invoice.invoiceId}</Link></td>
                                                <td>{invoice.invoiceDate}</td>
                                                <td><Link to={`/sales-order/${invoice.idSalesOrders}`}>{invoice.salesOrderId}</Link></td>
                                                <td>{numberFormat(invoice.invoiceAmount)}</td>
                                            </tr>

                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={4} className='text-center'>No Invoice Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                }

                {activityType === 'visit' &&
                    <div className={`card card-custom my-2`}>
                        <div className='card-header align-items-center border-0'>
                            <h3 className='card-title align-items-start flex-row'>
                                <span className='fw-bold mb-2 text-dark me-2'>Activity List</span>
                                <small className='mt-1'></small>
                            </h3>

                        </div>
                        <div className='card-body'>
                            {/* <SrActivityList srUserId={id} formData={formData}></SrActivityList> */}

                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th>Event</th>
                                        <th>Lead</th>
                                        <th>Contact Person</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo?.activities.length > 0 ? (
                                        userInfo?.activities.map((rowValue: any, slIndex: number) => (

                                            <tr key={rowValue.idLeads}>
                                                <td>
                                                    <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='symbol symbol-50px me-5'>
                                                                <KTSVG path={iconArray[rowValue.activityType].icon} className='svg-icon-danger svg-icon svg-icon-2'></KTSVG>
                                                            </div>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                <div className='text-dark fw-bold text-hover-primary fs-6'>
                                                                    {rowValue.activityDescription}
                                                                </div>
                                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                                    {(new Date(rowValue.createdAt)).toDateString()}, {(new Date(rowValue.createdAt)).toLocaleTimeString()}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </td>
                                                <td>
                                                    <a href={"/#/lead-details?idLeads=" + rowValue.idLeads}>
                                                        <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                            {rowValue.leadName}
                                                        </div>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{rowValue.companyCategoryName}</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block fs-6'>
                                                        {rowValue.contactPersonName}
                                                    </div>
                                                    <span className='text-muted fw-semibold text-muted d-block fs-7'>{rowValue.contactPersonPhone}</span>
                                                </td>
                                                <td>
                                                    {(rowValue.activityStatus === 'done') &&
                                                        <button type="button" className='btn btn-success  btn-sm fw-800 me-2'>
                                                            Done
                                                        </button>
                                                    }
                                                    {(rowValue.activityStatus === 'cancelled') &&
                                                        <button type="button" className='btn btn-danger btn-sm fw-800'>
                                                            Cancelled
                                                        </button>
                                                    }
                                                    {(rowValue.activityStatus === 'scheduled') &&
                                                        <button type="button" className='btn btn-info btn-sm fw-800'>
                                                            Scheduled
                                                        </button>
                                                    }
                                                </td>

                                            </tr>

                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={3} className='text-center'>No Lead Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>

                        </div>
                    </div>
                }


                {(activityType === 'sales' || activityType === 'quantity') &&
                    <div className={`card card-custom my-2`}>
                        <div className='card-header align-items-center border-0'>
                            <h3 className='card-title align-items-start flex-row'>
                                <span className='fw-bold mb-2 text-dark me-2'>Sales Order List</span>
                                <small className='mt-1'></small>
                            </h3>

                        </div>
                        <div className='card-body'>
                            {/* <DataTable url={salesOrderUrl} columns={salesOrderColumns} filters={salesOrderFilters} additionalPostData={additionalPostData} /> */}
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Date</th>
                                        <th className='text-center'>Order Sheet No</th>
                                        <th className='text-center'>Party Name</th>
                                        <th className='text-center'>Sales Person</th>
                                        <th className='text-center'>Item</th>
                                        <th className='text-center'>Package</th>
                                        <th className='text-center'>Size</th>
                                        <th className='text-center'>Remarks</th>
                                        <th className='text-center'>Quantity</th>

                                        <th className='text-center'>Rate</th>
                                        <th className='text-center'>Amount</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {orderSheetData.length > 0 ? (
                                        orderSheetData.map((order, slIndex) => (
                                            <React.Fragment key={order.idSalesOrders}>
                                                {order.rows.map((item, index) => (
                                                    <tr key={item.idSalesOrders}>
                                                        {index === 0 && (
                                                            <>
                                                                <td rowSpan={order.rows.length} className='text-center'>
                                                                    {order.salesOrderDate}
                                                                </td>
                                                                <td rowSpan={order.rows.length} className='text-center'>
                                                                    {order.salesOrderId === '' ? order.idSalesOrders : order.salesOrderId}
                                                                </td>
                                                                <td rowSpan={order.rows.length} className='text-center'>
                                                                    {order.company.companyName}
                                                                    {order.company.phones.map((phone: any, index: number) => (
                                                                        <div key={index}>{phone.phoneNumber}</div>
                                                                    ))}
                                                                    {order.company.companyAddress}
                                                                </td>
                                                                <td rowSpan={order.rows.length} className='text-center'>
                                                                    {order.salesPerson}
                                                                </td>
                                                            </>
                                                        )}
                                                        <td>{item.productName}</td>
                                                        <td>{item.packing}</td>
                                                        <td>{item.size}</td>
                                                        <td>{item.remarks ?? ''}</td>
                                                        <td className='text-end'>{numberFormat(item.quantity)}</td>

                                                        <td className='text-end'>{numberFormat(item.unitRate)}</td>
                                                        <td className='text-end'>{numberFormat(Number(item.quantity) * Number(item.unitRate))}</td>

                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={10} className='text-center'>No Data Found</td>
                                        </tr>
                                    )}
                                </tbody>
                                {/* <tfoot>
                                    <tr>
                                        <th colSpan={8} className='text-end'><strong>Total</strong></th>
                                        <th className='text-end'><strong>{numberFormat(totalQuantity)}</strong></th>

                                        <th></th>
                                        <th className='text-end'><strong>{numberFormat(totalAmount)}</strong></th>

                                    </tr>
                                </tfoot> */}

                            </Table>
                        </div>
                    </div>
                }
                {activityType === 'collection' &&
                    <div className={`card card-custom my-2`}>
                        <div className='card-header align-items-center border-0'>
                            <h3 className='card-title align-items-start flex-row'>
                                <span className='fw-bold mb-2 text-dark me-2'>Collection List</span>
                                <small className='mt-1'></small>
                            </h3>

                        </div>
                        <div className='card-body'>
                            <Table responsive bordered hover>
                                <thead>
                                    <tr>
                                        <th className='text-center'>Client Name</th>
                                        <th className='text-center'>Sales Order No</th>
                                        <th className='text-center'>Voucher ID</th>
                                        <th className='text-center'>Payment Date</th>
                                        <th className='text-center'>Payment Amount</th>
                                        <th className='text-center'>Received Through</th>
                                        <th className='text-center'>Narration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userInfo?.collection.length > 0 ? (
                                        userInfo?.collection.map((rowValue: any, slIndex: number) => (

                                            <tr key={rowValue.idAccVoucherRows}>
                                                <td>{rowValue.companyName}</td>
                                                <td className='text-center'>{rowValue.salesOrderId}</td>
                                                <td className='text-center'><Link to={`/transaction-details/${rowValue.id_accVouchers}`}>{rowValue.id_accVouchers}</Link></td>
                                                <td className='text-center'>{rowValue.voucherDate}</td>
                                                <td className='text-end'>{Number(rowValue.amount).toFixed(2)}</td>
                                                <td>{rowValue.drLedger}</td>
                                                <td>{rowValue.narration}</td>
                                            </tr>

                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6} className='text-center'>No Collection Data Found</td>
                                        </tr>
                                    )}
                                </tbody>
                                {/* {collectionData.length > 0 && (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={4} className='text-end'><strong>Total</strong></td>
                                            <td className='text-end'><strong>{numberFormat(totalSales)}</strong></td>
                                            <td className='text-end'><strong>{numberFormat(totalPayments)}</strong></td>
                                            <td className='text-end'><strong>{numberFormat(totalBalance)}</strong></td>
                                        </tr>
                                    </tfoot>
                                )} */}
                            </Table>
                        </div>
                    </div>
                }
            </div>
        </div >
    </>
    )
}

export default SrDetails
