/* eslint-disable react-hooks/exhaustive-deps */
import { KTSVG } from '../../_metronic/helpers'
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap-v5';
import Swal from 'sweetalert2'
import { Button, Form } from 'react-bootstrap'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import ProductImageUploader from '../components/ProductImageUploader';

interface Product {
	productName: string;
	idItems: number;
	unitName: string;
	packing: number;
	size: number;
	productionCost: number;
	previousCost: number;
	salePerUnit: number;
	costPerUnit: number;
	profitMargin: number;
	discountPercentAdmin: number;
	discountPercentSr: number;
	[key: string]: any; // Index signature
}

interface Category {
	id_item_categoriess: number;
	category_name: string;
	products: Product[];
}

const ProductList = () => {

	const [product, setProduct] = useState({});
	const [items, setItems] = useState([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [projects, setProjects] = useState([]);
	const [project, setProject] = useState({ label: 'Select Project', value: '' });
	const [showOnlyWithStock, setShowOnlyWithStock] = useState(true);

	useEffect(() => {
		getAllProjects()
		getCategoryUnits(null)
	}, [])

	const [showProductImageModal, setShowProductImageModal] = useState(false);
	const handleProductImageUploaderClose = (isShow: any) => {
		setShowProductImageModal(false);
		// getVisitPlan(selectedProject?.value, fromDate, toDate, sr?.value)
	}

	const showImageUploader = (product: any) => {
		setProduct(product)
		setShowProductImageModal(true);
	}


	const getAllProjects = () => {
		fetch(
			process.env.REACT_APP_API_URL + 'projects',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				resp.data.map((item: any) => {
					item.label = item.project_name;
					item.value = item.id_projects;
					return item
				})

				var allProjectsOpt = [{ id: '', name: '', label: 'All Project', value: '' }]
				let allProjects = allProjectsOpt.concat(resp.data);
				setProjects(allProjects);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}

	const handleProjectChange = (project: any) => {
		setProject(project);
		getCategoryUnits(project);
	}


	const handleItemChange = (data: any, catIndex: number, productIndex: number) => {

		const updatedCategories = [...categories];
		updatedCategories[catIndex].products[productIndex].idItems = data.value;
		updatedCategories[catIndex].products[productIndex].unitName = data.unit_name;
		updatedCategories[catIndex].products[productIndex].productName = data.item_name;

		setCategories(updatedCategories);

	}

	const getCategoryUnits = (project: any, showOnlyWithStock=true) => {

		//get items
		fetch(
			process.env.REACT_APP_API_URL + 'allItems',
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let units = resp.map((item: any) => {
					item.label = item.item_name;
					item.value = item.id_items;
					return item
				})
				setItems(units);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})

		//get Categories
		fetch(
			process.env.REACT_APP_API_URL + 'allProductCategories?idProjects=' + ((project === null) ? '' : project.value)+'&showOnlyWithStock=' + showOnlyWithStock,
			getRequestOptions()
		)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let categories = resp.map((item: any) => {
					item.label = item.category_name;
					item.value = item.id_item_categoriess;
					return item
				})
				setCategories(categories);

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


	const [isSubmitDisable, setSubmitDisable] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, catIndex: number, productIndex: number) => {

		const updatedCategories = [...categories];
		updatedCategories[catIndex].products[productIndex][e.target.name] = e.target.value;

		if (e.target.name === 'packing') {
			if (!isNaN(updatedCategories[catIndex].products[productIndex].packing)) {
				updatedCategories[catIndex].products[productIndex].size = updatedCategories[catIndex].products[productIndex].packing
			} else {
				updatedCategories[catIndex].products[productIndex].size = 0
			}
		}

		if (updatedCategories[catIndex].products[productIndex].productionCost && updatedCategories[catIndex].products[productIndex].size) {
			updatedCategories[catIndex].products[productIndex].costPerUnit = parseFloat((updatedCategories[catIndex].products[productIndex].productionCost / updatedCategories[catIndex].products[productIndex].size).toFixed())
		}
		if (updatedCategories[catIndex].products[productIndex].salePerUnit > 0 && updatedCategories[catIndex].products[productIndex].productionCost > 0) {
			updatedCategories[catIndex].products[productIndex].salePerUnitCost = (updatedCategories[catIndex].products[productIndex].salePerUnit / updatedCategories[catIndex].products[productIndex].size).toFixed(2)
			updatedCategories[catIndex].products[productIndex].profitMargin = parseFloat(((updatedCategories[catIndex].products[productIndex].salePerUnit - updatedCategories[catIndex].products[productIndex].productionCost) / updatedCategories[catIndex].products[productIndex].productionCost * 100).toFixed())
		}
		setCategories(updatedCategories);
	}

	const deleteProduct = (catIndex: number, productIndex: number) => {

		const updatedCategories = [...categories];
		if (updatedCategories[catIndex]) {
			const products = categories[catIndex].products;

			if (products && products[productIndex]) {
				updatedCategories[catIndex].products.splice(productIndex, 1);
				setCategories(updatedCategories);
			}
		}
	}

	const productSave = async (event: any) => {
		event.preventDefault()
		setSubmitDisable(true);
		setLoading(true);
		const confirm = await Swal.fire({
			title: 'Are you sure?',
			text: "You want to save this Product(s)?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ec008c',
			cancelButtonText: 'Cancel',
			confirmButtonText: 'Yes, Save it!',
		})
		if (confirm.value) {
			const requestOptions = postRequestOptions({ ...categories, idProjects: project.value })//postRequestOptions(categories)
			try {
				let response = await fetch(
					`${process.env.REACT_APP_API_URL}products/store`,
					requestOptions
				)
				let respData = await response.json()
				if (respData.success === true) {

					getCategoryUnits(project)
					const successMsg = respData.successMessage
					Swal.fire({
						icon: 'success',
						title: 'Success',
						text: successMsg,
					})
				} else {
					const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
					Swal.fire({
						icon: 'error',
						title: 'Error',
						html: errorsMessage,
					})
				}
				setSubmitDisable(false);
				setLoading(false);
			} catch (error) {
				console.log(error, 'catch the hoop')
				setSubmitDisable(false);
				setLoading(false);
			}
		}
	}

	const addProduct = (index: number) => {
		const newProduct: Product = {
			productName: '',
			idItems: 0,
			unitName: ' ',
			packing: 0,
			size: 0,
			productionCost: 0,
			previousCost: 0,
			salePerUnit: 0,
			costPerUnit: 0,
			profitMargin: 0,
			discountPercentAdmin: 0,
			discountPercentSr: 0,
		};

		const updatedCategories = [...categories];
		updatedCategories[index].products.push(newProduct);

		setCategories(updatedCategories);
	}

	// Handle the change in switch
    const showOnlyWithStockChange = (checked: any) => {
		Swal.showLoading();
        setShowOnlyWithStock(checked);
		getCategoryUnits(project, checked);
		Swal.close();
    };


	return (
		<>
			<form onSubmit={productSave}>
				<div className="form-group row">
					<label htmlFor="project" className="col-lg-3 col-form-label" style={{ "textAlign": "right" }}>Project </label>
					<div className="col-lg-5">
						<Select
							value={project}
							onChange={handleProjectChange}
							options={projects}
							menuPosition="fixed"
						/>
					</div>
					<div className="col-md-3" style={{marginTop: "-10px"}}>
						<Form.Group controlId="showOnlyWithStock">
							<Form.Label></Form.Label>
							<Form.Check // prettier-ignore
								type="switch"
								id="custom-switch"
								label="Show only products with stock"
								className="form-switch"
								checked={showOnlyWithStock===true} // Bound to state
								onChange={(e) => showOnlyWithStockChange(e.target.checked)} // Pass the checked value
							/>
						</Form.Group>
					</div>
				</div>

				<div className="form-group row mt-6">

					<div className="col">
						<Table size='sm' bordered hover>
							<thead>
								<tr>
									<th style={{ width: '26%' }}>Category/Product Name</th>
									<th style={{ width: '8%' }}>Packing</th>
									<th style={{ width: '7%' }}>Size</th>
									<th style={{ width: '6%' }}>Production Cost</th>
									<th style={{ width: '6%' }}>Sale Amount</th>
									<th style={{ width: '5%' }}>Discount (%) - Admin Level</th>
									<th style={{ width: '5%' }}>Discount (%) - SR Level</th>
									<th style={{ width: '5%' }}>Profit (%)</th>
									<th style={{ width: '5%' }}>Cost Setup</th>
									<th style={{ width: '5%' }}>Image</th>
									<th style={{ width: '10%' }}></th>
								</tr>
							</thead>

							{categories?.map((category, catIndex) => (
								<tbody key={catIndex}>
									<tr>
										<td colSpan={9}><b>{category.category_name}</b></td>
										<td colSpan={2} className='text-center'>
											<Button variant='success' size="sm" onClick={() => addProduct(catIndex)}>Add Product</Button>
										</td>
									</tr>

									{category.products.map((product, productIndex) => (
										<tr key={catIndex + '.' + productIndex}>
											<td>
												{product.idProducts === undefined &&
													<Select
														// value={product.idItems}
														// defaultValue={['label': 'ddd', 'value': 0]} 
														placeholder={product.productName}
														onChange={(selectedOption) => handleItemChange(selectedOption, catIndex, productIndex)}
														options={items}
														className='sm'
														menuPosition="fixed"
														styles={{
															control: (provided, state) => ({
																...provided,
																height: '32px',
																minHeight: '32px',
															}),
															container: (provided, state) => ({
																...provided,
																height: '32px',
															}),
														}}
													/>
												}

												{product.idProducts !== undefined &&
													<>{product.productName}</>
												}
											</td>
											<td>
												<div className="input-group">
													<input type="text" name="packing" value={product.packing}
														onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, catIndex, productIndex)}
														className="form-control form-control-sm" />
													<span className="input-group-text" style={{ height: "32px" }}>{product.unitName}</span>
												</div>

											</td>
											<td>
												<div className="input-group">
													<input type="number" name="size" value={product.size}
														onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, catIndex, productIndex)}
														className="form-control form-control-sm" />
													<span className="input-group-text" style={{ height: "32px" }}>{product.unitName}</span>
												</div>

											</td>
											<td>
												<input type="number" name="productionCost" value={product.productionCost} onChange={(event) => handleChange(event, catIndex, productIndex)} className="form-control form-control-sm" />
											</td>
											{/* <td>
												<input type="text" name="costPerUnit" readOnly value={product.costPerUnit} className="form-control form-control-sm" />
											</td> */}
											<td>
												<input type="number" name="salePerUnit" value={product.salePerUnit} onChange={(event) => handleChange(event, catIndex, productIndex)} className="form-control form-control-sm" />
											</td>
											{/* <td>
												<input type="number" name="salePerUnitCost" value={product.salePerUnitCost} readOnly className="form-control form-control-sm" />
											</td> */}
											<td>
												<input type="number" name="discountPercentAdmin" value={product.discountPercentAdmin} onChange={(event) => handleChange(event, catIndex, productIndex)} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="number" name="discountPercentSr" value={product.discountPercentSr} onChange={(event) => handleChange(event, catIndex, productIndex)} className="form-control form-control-sm" />
											</td>
											<td>
												<input type="text" name="profitMargin" readOnly value={product.profitMargin} className="form-control form-control-sm" />
											</td>
											<td className='text-center'>
												{product.idProducts !== undefined && product.cost !== null &&
													<Link type='button' className="btn btn-success btn-sm" to={`/production-cost-setting/${product.idProducts}`} title='Production Cost Settings'>
														Yes
													</Link>
												}
												{product.idProducts !== undefined && product.cost === null &&
													<Link type='button' className="btn btn-danger btn-sm" to={`/production-cost-setting/${product.idProducts}`} title='Production Cost Settings'>
														No
													</Link>
												}
											</td>
											<td className='text-center'>
												{product.image !== null &&
													<img src={`${process.env.REACT_APP_AWS_URL}next-crm/product_image/${product.image}`} alt="pic" style={{ width: "30", height: "50px" }} />
												}
											</td>
											<td className='text-center'>
												{product.idProducts !== undefined &&
													<button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => showImageUploader(product)} title='Upload Image'>
														<KTSVG path='/media/svg/icons/Design/Image.svg' className='svg-icon-5 svg-icon-danger me-0' />
													</button>
												}
												<button type='button' className="btn btn-light-primary btn-sm px-2 py-1" onClick={() => deleteProduct(catIndex, productIndex)} title='Delete'>
													<KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon-5 svg-icon-danger me-0' />
												</button>
											</td>
										</tr>
									))}

								</tbody>

							))}

						</Table>

						<div className='row'>
							<div className='col text-center'>
								<button type="submit" disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2">
									{loading &&
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									}
									<span>  Submit</span>
								</button>
								<Button variant='secondary' size="sm">
									Close
								</Button>
							</div>
						</div>
					</div>
				</div>


				{/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
				<ProductImageUploader show={showProductImageModal} handleClose={handleProductImageUploaderClose} id={Date.now()} product={product}></ProductImageUploader>


			</form>

		</>
	)
}

export default ProductList
