import React, { useState, useEffect, useCallback } from 'react';
import { getRequestOptions } from '../helpers/Fetchwrapper';
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Table } from 'react-bootstrap-v5';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import { Link } from 'react-router-dom'

interface Item {
    id_items: number;
    itemName: string;
    itemCode: string;
    stock: number;
    total_stock_in: number;
    total_stock_out: number;
    unit: { unitName: string };
    item_category?: { categoryName: string };
}

interface Product {
    productName: string;
    packing: string;
    size: string;
    categoryName: string;
    stock: number;
    total_stock_in: number;
    total_stock_out: number;
    item?: { unit?: { unitName: string } };
}


interface Column {
    header: string;
    accessor: string;
    render?: (data: Item | Product) => React.ReactNode;
}

// Reusable Stock Table Component
const StockTable: React.FC<{ data: Item[] | Product[]; columns: Column[]; title: string }> = ({ data, columns, title }) => (
    <Col md={12}>
        <h4>{title}</h4>
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    {columns.map((col, idx) => <th key={idx}>{col.header}</th>)}
                </tr>
            </thead>
            <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        {columns.map((col, idx) => (
                            <td key={idx}>
                                {col.render ? col.render(item) : (item as any)[col.accessor]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </Table>
    </Col>
);

const InventoryStockStatus: React.FC = () => {

    const [items, setItems] = useState<Item[]>([]);
    const [products, setProducts] = useState<Product[]>([]);

    const [selectProjectOptions, setSelectProjectOptions] = useState([])
    const [selectedProject, setSelectedProject] = useState<{ value: string; label: string; } | null>(null);

    const getProjectList = async () => {
        const url = process.env.REACT_APP_API_URL + 'projects';

        const response = await fetch(url, getRequestOptions());

        const data = await response.json();

        data?.data?.map((element: any) => {
            element.value = element.id_projects;
            element.label = element.project_name;
            return element;
        })

        setSelectProjectOptions(data.data);
    }

    useEffect(() => {
        getProjectList();
    }, [])

    const getStockStatus = useCallback(async () => {
        let idProjects = selectedProject?.value ?? '';
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + 'inventory-stock-status?id_projects=' + idProjects, getRequestOptions());
            const data = await response.json();
            setItems(data.data.items);
            setProducts(data.data.products);
        } catch (error) {
            console.error('catch the hoop', error);
        }
    }, [selectedProject]);

    const numberFormat = (value: number) => {
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
    // Define columns for items table
    const itemColumns: Column[] = [
        { header: 'SL. No', accessor: 'index' },
        { header: 'Item Name', accessor: 'itemName', render: item => <Link to={'/item-stock-details?id_items=' + item?.id_items + '&id_projects=' + (selectedProject?.value ?? '')} className="label label-info">{item.itemName}</Link> },
        { header: 'Item Code', accessor: 'itemCode' },
        { header: 'Item Category', accessor: 'item_category', render: item => item?.item_category?.categoryName },
        { header: 'Stock', accessor: 'stock', render: item => <span className={Number(item.total_stock_in - item.total_stock_out) <= 0 ? 'text-danger' : ''}>{Number(item.total_stock_in - item.total_stock_out) ? numberFormat(Number(item.total_stock_in - item.total_stock_out)) : 0}</span> },
        { header: 'Unit', accessor: 'unit', render: item => item.unit.unitName },
    ];

    // Define columns for products table
    const productColumns: Column[] = [
        { header: 'SL. No', accessor: 'index' },
        { header: 'Product Name', accessor: 'productName', render: item => <Link to={'/finished-goods-stock-details?id_products=' + item?.id_products + '&id_projects=' + (selectedProject?.value ?? '')} className="label label-info">{item.productName}</Link> },
        { header: 'Packing', accessor: 'packing' },
        { header: 'Size', accessor: 'size' },
        { header: 'Category', accessor: 'categoryName' },
        { header: 'Stock', accessor: 'stock', render: item => <span className={Number(item.total_stock_in - item.total_stock_out) <= 0 ? 'text-danger' : ''}>{Number(item.total_stock_in - item.total_stock_out) ? numberFormat(Number(item.total_stock_in - item.total_stock_out)) : 0}</span> },
        { header: 'Unit', accessor: 'unit', render: product => product?.item?.unit?.unitName },
    ];


    useEffect(() => {
        getStockStatus()
    }, [getStockStatus])

    const handleSelectProjectChange = (selectedOption: any) => {
        // Set the selected project state
        setSelectedProject(selectedOption);
        getStockStatus()
    };

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <div className="card-title">
                        <KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-primary' />
                        <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                            Inventory Stock Status
                        </span>
                    </div>
                    <div className="card-toolbar">


                        <div className='d-flex justify-content-end'>
                            <Select
                                defaultValue={selectProjectOptions}
                                onChange={handleSelectProjectChange}
                                options={selectProjectOptions}
                                placeholder="Select project"
                            />
                        </div>
                        &nbsp;&nbsp;<a className="btn btn-sm btn-primary" href={`${process.env.REACT_APP_BACKEND}inventory_stock_status_download`}><i className='fas fa-download'></i>Excel Download</a>
                    </div>
                </div>

                <div className='card-body'>
                    <Row>
                        <StockTable
                            data={items.map((item, index) => ({ ...item, index: index + 1 }))}
                            columns={itemColumns}
                            title="Item Stock"
                        />

                        <StockTable
                            data={products.map((product, index) => ({ ...product, index: index + 1 }))}
                            columns={productColumns}
                            title="Finished Goods Stock"
                        />

                    </Row>

                </div>
            </div>

        </>
    )
}

export default InventoryStockStatus
