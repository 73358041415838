/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper';
import clsx from 'clsx'
import { KTSVG } from '../../_metronic/helpers';
import { Col, Row, Table } from 'react-bootstrap-v5';
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Swal from 'sweetalert2'
import showLoader from '../helpers/ShowLoader'
import 'react-dropzone-uploader/dist/styles.css'
import axios, { AxiosResponse, AxiosError } from 'axios'
import Dropzone from 'react-dropzone-uploader'
import { useParams } from 'react-router-dom'
import ShipmentCreate from '../components/ShipmentCreate'
import EditSr from '../components/EditSr'
import EditSalesOrderItem from '../components/EditSalesOrderItem'
import SalesPaymentCreate from '../components/SalesPaymentCreate'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom'

interface SalesOrder {
    editPermission: boolean;
    deletePermission: boolean;
    id_sales_orders: number;
    idSalesOrders: number;
    createdBy: number;
    idProjects: number;
    idLeads: number;
    createdAt: any;
    salesOrderDate: string;
    shippingAddress: string;
    billingAddress: string;
    purchaseOrderNo: string;
    requisitionNo: string;
    salesType: string;
    remarks: string;
    idSr: number;
    salesOrderId: string;
    sales_representative: any;
    creditDays: number;
    creditDaysFrom: string;
    idSalesQuotations: number;
    totalDiscount: number;
    orderAmount: number;
    leadName: string;
    projectName: string;
    shipments_sum_shipment_amount: number;
    payments_sum_payment_amount: number;
    invoices_sum_invoice_amount: number;
    creator: {
        name: string;
    };
    terms: { id_sales_orders: number, terms_clause: string }[];
    comments: { id_sales_orders: number, comment: string, created_at: string, creator: any }[];
    attachments: { id_sales_orders: number, attachment_name: string, attachment_path: string, file_size: number, created_at: string, creator: any }[];
    rows: { id_sales_orders: number, unit_rate: number, quantity: number, discount_percent: number, product_name: string, original_rate: number, already_delivered: string, unit_rate_without_transportation: number, transportation_cost: number, product: any }[];

    project: {
        projectName: string;
    };
    company: any;
    quotation: {
        referenceNumber: string;
    };
    shipments: {
        id_shipments: number, shipment_date: string, chalan_no: string, shipping_address: string
        , vehicle_no: string, driver_name: string, driver_phone_no: string, rows: {
            quantity: string; sales_order_row: any;
        }[]
    }[];
    invoices: {
        id_sales_invoice: number, invoice_id: string, invoice_date: number, invoice_amount: number, created_by: string
    }[];
    payments: {
        id_sales_payments: number, transaction_id: string, id_sales_invoice: number, payment_amount: number, creator: any
        , payment_date: string, payment_method: string, payment_type: string, remarks: string, paymentInfo: string
    }[];
}

interface RouteParams {
    idSalesOrders: string;
}

const getReadableFileSizeString = (fileSizeInBytes: number) => {
    return ((fileSizeInBytes / (1024 * 1024)).toFixed(4) + " MB");
}

export function SaledOrderDetails(props: any) {

    const [tab, setTab] = useState('Sidebar')
    const [salesOrderInfo, setSalesOrderInfo] = useState<SalesOrder | null>(null);
    const { idSalesOrders }: RouteParams = useParams();

    const numberFormat = (value: any) => {

        value = parseFloat(value)
        if (typeof (value) !== 'number') {
            return value;
        }
        return parseFloat(value.toString()).toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    const [createShipmentModalShow, setCreateShipmentModalShow] = useState(false);
    const [createPaymentModalShow, setCreatePaymentModalShow] = useState(false);
    const [editSrModalShow, setEditSrModalShow] = useState(false);
    const [editItemModalShow, setEditItemModalShow] = useState(false);
    const handleCreateShipmentModalClose = (isReload: boolean) => {
        setCreateShipmentModalShow(false);
        getSalesOrderInfo();
    }
    const handleCreatePaymentModalClose = (isReload: boolean) => {
        setCreatePaymentModalShow(false);
        getSalesOrderInfo();
    }
    const handleEditSrModalClose = (isReload: boolean) => {
        setEditSrModalShow(false);
        getSalesOrderInfo();
    }
    const handleEditItemModalClose = (isReload: boolean) => {
        setEditItemModalShow(false);
        getSalesOrderInfo();
    }

    const handleShipmentModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setCreateShipmentModalShow(true);
    }

    const handlePaymentModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setCreatePaymentModalShow(true);
    }

    const handleEditSrModalShow = (salesOrderInfo: any) => {
        setSalesOrderInfo(salesOrderInfo);
        setEditSrModalShow(true);
    }

    const handleEditItemModalShow = (salesOrderInfo: any) => {
        // setSalesOrderInfo(salesOrderInfo);
        setEditItemModalShow(true);
    }

    let history = useHistory();
    const deleteSalesOrder = (idSalesOrders: any) => {
        if (window.confirm("Are you sure you want to delete this Sales Order?")) {
            const myToken: string | null = localStorage.getItem('MyToken');
            let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
            fetch(`${process.env.REACT_APP_API_URL}sales-order/${idSalesOrders}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
            })
                .then((resp: Response) => {
                    return resp.json();
                })
                .then((resp: any) => {
                    if (resp.success) {
                        const successMsg: string = resp.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: successMsg,
                        });
                        history.push('/sales-orders');
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Delete Failed',
                            text: resp.errorMessage,
                        });
                    }
                })
                .catch((error: any) => {
                    console.log(error, 'catch the hoop');
                });
        }

    }

    const [message, setMessage] = useState('')

    useEffect(() => {
        if (idSalesOrders !== undefined) {
            getSalesOrderInfo();
        }
    }, [idSalesOrders])

    const getSalesOrderInfo = () => {
        fetch(
            process.env.REACT_APP_API_URL + 'sales-order/' + idSalesOrders,
            getRequestOptions()
        )
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const data: SalesOrder = resp.data;
                data.projectName = resp.data.project.projectName;
                data.editPermission = resp.editPermission;
                data.deletePermission = resp.deletePermission;
                setSalesOrderInfo(data);
            })
            .catch((error) => {
                console.log(error, 'catch the hoop')
            })
    }

    const sendMessage = async () => {
        showLoader()
        const formData = {
            idSalesOrders: idSalesOrders,
            message: message
        };
        const requestOptions = postRequestOptions(formData)
        try {
            let response = await fetch(
                `${process.env.REACT_APP_API_URL}sales-order/comment`,
                requestOptions
            )
            let respData = await response.json()
            Swal.close();
            if (respData.success === true) {
                setMessage('');
                getSalesOrderInfo()
            } else {
                const errorsMessage = validationErrorMsg(respData.errors).join('<br>')
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                })
            }

        } catch (error) {
            console.log(error, 'catch the hoop')
            Swal.close();
        }
    }

    const jwt = () => {
        const myToken = localStorage.getItem('MyToken');
        return (myToken != null) ? JSON.parse(myToken) : null;
    }


    const handleFileSubmit = (files: any, allFiles: []) => {
        showLoader()

        const postFormData = new FormData();
        postFormData.append('idSalesOrders', idSalesOrders.toString());
        for (let i = 0; i < files.length; i++) {
            postFormData.append('files[]', files[i].file);
        }

        const url: string = `${process.env.REACT_APP_API_URL}sales-order/file-upload`;

        axios({
            method: 'POST',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + jwt(),
            },
            data: postFormData,
        })
            .then((response: AxiosResponse<any>) => {
                Swal.close();
                allFiles.forEach((f: any) => f.remove());
                getSalesOrderInfo();
            })
            .catch((error: AxiosError<any>) => {
                Swal.close();
                // const errorMessage: string = error.response?.data?.errorMessage;
                const errorsMessage: string = validationErrorMsg(error?.response?.data?.errors).join('<br>');
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    html: errorsMessage,
                });
            });

    }


    const deleteSalesInvoice = (idSalesInvoice: number) => {
        if (window.confirm("Are you sure you want to delete this Sales Invoice?")) {
            const myToken: string | null = localStorage.getItem('MyToken');
            let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
            fetch(`${process.env.REACT_APP_API_URL}sales-invoice/${idSalesInvoice}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
            })
                .then((resp: Response) => {
                    return resp.json();
                })
                .then((resp: any) => {
                    if (resp.success) {
                        const successMsg: string = resp.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: successMsg,
                        });
                        getSalesOrderInfo()
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Delete Failed',
                            text: resp.errorMessage,
                        });
                    }
                })
                .catch((error: any) => {
                    console.log(error, 'catch the hoop');
                });
        }
    }

    const deleteShipment = (idShipments: number) => {
        if (window.confirm("Are you sure you want to delete this Shipment?")) {
            const myToken: string | null = localStorage.getItem('MyToken');
            let jwt: string | null = (myToken != null) ? JSON.parse(myToken) : null;
            fetch(`${process.env.REACT_APP_API_URL}shipment/${idShipments}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwt}` }
            })
                .then((resp: Response) => {
                    return resp.json();
                })
                .then((resp: any) => {
                    if (resp.success) {
                        const successMsg: string = resp.successMessage;
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: successMsg,
                        });
                        getSalesOrderInfo()
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Delete Failed',
                            text: resp.errorMessage,
                        });
                    }
                })
                .catch((error: any) => {
                    console.log(error, 'catch the hoop');
                });
        }
    }

    return (<>
        <div className='card card-custom'>
            <div className='card-header card-header-stretch overflow-auto'>
                <div className='card-title'>
                    <h3 className='card-label'>Saled Order Details</h3>
                    <small className='text-muted'>| Information about the sales Order</small>
                </div>
            </div>
            <div className="card-body">

            </div>
        </div>

        <Tabs
            defaultActiveKey="orderInfo"
            id="uncontrolled-tab-example"
            className="mb-3"
        >
            <Tab eventKey="orderInfo" title="Order Details">
                <Row>
                    <Col md={4} className="pe-0 pt-0">
                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Summary</span>
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                {/* <pre>{JSON.stringify(salesOrderInfo, null, 2)}</pre> */}
                                <Table striped bordered hover size='sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%' }}>ID</td>
                                            <td style={{ width: '50%' }}>{salesOrderInfo?.id_sales_orders}</td>
                                        </tr>
                                        <tr>
                                            <td>Sales Order ID</td>
                                            <td>{salesOrderInfo?.salesOrderId}</td>
                                        </tr>
                                        <tr>
                                            <td>Project</td>
                                            <td>{salesOrderInfo?.project?.projectName}</td>
                                        </tr>
                                        <tr>
                                            <td>Lead</td>
                                            <td>{salesOrderInfo?.leadName}</td>
                                        </tr>
                                        <tr>
                                            <td>Date</td>
                                            <td>{salesOrderInfo?.salesOrderDate}</td>
                                        </tr>
                                        <tr>
                                            <td>Billing Address</td>
                                            <td>{salesOrderInfo?.billingAddress}</td>
                                        </tr>
                                        <tr>
                                            <td>Shipping Address</td>
                                            <td>{salesOrderInfo?.shippingAddress}</td>
                                        </tr>
                                        <tr>
                                            <td>Credit Days</td>
                                            <td>{salesOrderInfo?.creditDays} from {salesOrderInfo?.creditDaysFrom}</td>
                                        </tr>
                                        <tr>
                                            <td>Sales Representative</td>
                                            <td>
                                                {salesOrderInfo?.sales_representative !== null ? (salesOrderInfo?.sales_representative?.first_name + ' ' + salesOrderInfo?.sales_representative?.last_name) : ''}
                                                {salesOrderInfo?.editPermission &&
                                                    <button className="btn btn-light-primary btn-sm pull-right" onClick={() => handleEditSrModalShow(salesOrderInfo)}>
                                                        <KTSVG
                                                            path='/media/svg/icons/Communication/Write.svg'
                                                            className='svg-icon-5 svg-icon'
                                                        />		Edit
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Purcahse Order No</td>
                                            <td>{salesOrderInfo?.purchaseOrderNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Requisition No</td>
                                            <td>{salesOrderInfo?.requisitionNo}</td>
                                        </tr>
                                        <tr>
                                            <td>Sales Type</td>
                                            <td>{salesOrderInfo?.salesType}</td>
                                        </tr>
                                        <tr>
                                            <td>Remarks</td>
                                            <td>{salesOrderInfo?.remarks}</td>
                                        </tr>
                                        <tr>
                                            <td>Quotation</td>
                                            <td>{salesOrderInfo?.quotation?.referenceNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Creator</td>
                                            <td>{salesOrderInfo?.creator.name + ' at ' + salesOrderInfo?.createdAt}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Lead Details</span>
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                <Table striped bordered hover size='sm'>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '50%' }}>Lead Name</td>
                                            <td style={{ width: '50%' }}>{salesOrderInfo?.leadName}</td>
                                        </tr>
                                        <tr>
                                            <td>Client</td>
                                            <td>{salesOrderInfo?.company?.company_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Category</td>
                                            <td>{salesOrderInfo?.company?.company_category?.category_name}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{salesOrderInfo?.company?.company_address}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className='card card-custom'>
                            <div className='card-header card-header-stretch overflow-auto'>
                                <ul
                                    className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                                    role='tablist'
                                >
                                    <li className='nav-item p-2'>
                                        <a
                                            className={clsx(`nav-link cursor-pointer`, { active: tab === 'Sidebar' })}
                                            onClick={() => setTab('Sidebar')}
                                            role='tab'
                                        >
                                            <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/Communication/Chat6.svg"></KTSVG>
                                            Add comment
                                        </a>
                                    </li>
                                    <li className='nav-item p-2'>
                                        <a
                                            className={clsx(`nav-link cursor-pointer`, { active: tab === 'Toolbar' })}
                                            onClick={() => setTab('Toolbar')}
                                            role='tab'
                                        >
                                            <KTSVG className='svg-icon-3 me-2' path="/media/svg/icons/General/Clip.svg"></KTSVG>
                                            Attach files
                                        </a>
                                    </li>
                                </ul>
                            </div>


                            <div className='card-body'>
                                <div className='tab-content pt-3'>
                                    <div className={clsx('tab-pane', { active: tab === 'Sidebar' })}>

                                        <Table striped bordered hover size='sm'>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '50%' }}>Comment</th>
                                                    <th style={{ width: '50%' }}>Comment By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {salesOrderInfo?.comments?.length === 0 &&
                                                    <tr>
                                                        <td colSpan={2}>No comments available</td>
                                                    </tr>
                                                }
                                                {salesOrderInfo?.comments?.map((comment, index) => (
                                                    <tr>
                                                        <td key={'comment' + index}>{comment.comment}</td>
                                                        <td>{comment.creator.first_name + ' ' + comment.creator.last_name + ' at ' + comment.created_at}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                        <textarea
                                            className='form-control  mb-3'
                                            rows={3}
                                            data-kt-element='input'
                                            placeholder='Type a message'
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                        ></textarea>
                                        <div className='d-flex flex-row justify-content-end'>

                                            <button
                                                className='btn-sm btn-success'
                                                type='button'
                                                onClick={sendMessage}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </div>

                                    <div className={'p-0 ' + clsx('tab-pane', { active: tab === 'Toolbar' })}>

                                        <Table striped bordered hover size='sm'>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '50%' }}>Attachment</th>
                                                    <th style={{ width: '50%' }}>Uploaded By</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {salesOrderInfo?.attachments?.length === 0 &&
                                                    <tr>
                                                        <td colSpan={2}>No File Attached</td>
                                                    </tr>
                                                }
                                                {salesOrderInfo?.attachments?.map((attachment, index) => (
                                                    <tr>
                                                        <td key={'attachment' + index}>
                                                            <a rel='noreferrer' href={process.env.REACT_APP_AWS_URL + "salesOrders/" + attachment.attachment_path}
                                                                className="fs-6 text-hover-primary fw-bold" target="_blank">{attachment.attachment_name}</a>
                                                            <div className="text-gray-400">{getReadableFileSizeString(attachment.file_size)}</div>
                                                        </td>
                                                        <td>{attachment.creator.first_name + ' ' + attachment.creator.last_name + ' at ' + attachment.created_at}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        <Dropzone
                                            styles={{ dropzone: { border: "none" } }}
                                            accept='image/*,audio/*,application/pdf'
                                            onSubmit={handleFileSubmit}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </Col>
                    <Col md={8} className="pt-2">

                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Items</span>
                                </h3>
                                {salesOrderInfo?.editPermission && salesOrderInfo?.shipments.length === 0 &&
                                    <div className='card-toolbar'>
                                        <button className="btn btn-light-primary btn-sm pull-right" onClick={() => handleEditItemModalShow(salesOrderInfo)}>
                                            <KTSVG
                                                path='/media/svg/icons/Communication/Write.svg'
                                                className='svg-icon-5 svg-icon'
                                            />		Edit
                                        </button>
                                    </div>
                                }
                            </div>
                            <div className="card-body pt-0">
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Packing</th>
                                            <th>Order Quantity</th>
                                            <th>Already Delivered</th>
                                            <th>Original Rate (Per Unit)</th>
                                            <th>Discount(%)</th>
                                            <th>Rate After Discount</th>
                                            <th>Transportation Cost (Per Unit)</th>
                                            <th>Effective Rate (Rate + Transportation Cost)</th>
                                            <th>Amount</th>
                                            <th>Amount after Discount</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {salesOrderInfo?.rows?.map((item, index) => (
                                            <>
                                                <tr key={'item' + index}>
                                                    <td>{item?.product.product_name}</td>
                                                    <td>{item?.product.packing}</td>
                                                    <td className='text-center'>{item?.quantity}</td>
                                                    <td className='text-center'>{item?.already_delivered}</td>
                                                    <td className='text-end'>{numberFormat(item?.original_rate)}</td>
                                                    <td className='text-end'>{item?.discount_percent}</td>
                                                    <td className='text-end'>{numberFormat(item?.unit_rate_without_transportation)}</td>
                                                    <td className='text-end'>{numberFormat(item?.transportation_cost)}</td>
                                                    <td className='text-end'>{numberFormat(item?.unit_rate)}</td>
                                                    <td className='text-end'>{numberFormat(item?.quantity * item?.original_rate)}</td>
                                                    <td className='text-end'>{numberFormat((item?.quantity * item?.unit_rate))}</td>
                                                </tr>
                                            </>

                                        ))}
                                        <tr>
                                            <td colSpan={10} className='text-end'><b>Total Amount : </b></td>
                                            <td className='text-end'><b>{numberFormat(salesOrderInfo?.orderAmount)}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={10} className='text-end'><b>Total Discount : </b></td>
                                            <td className='text-end'><b>{numberFormat(salesOrderInfo?.totalDiscount)}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={10} className='text-end'><b>Net Amount : </b></td>
                                            <td className='text-end'><b>{numberFormat((salesOrderInfo?.orderAmount || 0) - (salesOrderInfo?.totalDiscount || 0))}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={10} className='text-end'><b>Total Shipment Amount : </b></td>
                                            <td className='text-end'><b>{numberFormat(salesOrderInfo?.shipments_sum_shipment_amount)}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={10} className='text-end'><b>Total Invoiced Amount : </b></td>
                                            <td className='text-end'><b>{numberFormat(salesOrderInfo?.invoices_sum_invoice_amount)}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={10} className='text-end'><b>Total Payment Received : </b></td>
                                            <td className='text-end'><b>{numberFormat(salesOrderInfo?.payments_sum_payment_amount)}</b></td>
                                        </tr>

                                    </tbody>
                                </Table>
                                <div className='row'>
                                    <div className='col text-center'>

                                        <button className="btn btn-primary btn-sm" onClick={() => handleShipmentModalShow(salesOrderInfo)}>
                                            <KTSVG
                                                path='/media/svg/icons/Shopping/Cart3.svg'
                                                className='svg-icon-5 svg-icon'
                                            />		Create Shipment
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Link className="btn btn-primary btn-sm" to={`/sales-invoice-create?idSalesOrder=${salesOrderInfo?.id_sales_orders}&idProjects=${salesOrderInfo?.idProjects}&projectName=${salesOrderInfo?.project?.projectName}`}>
                                            <KTSVG
                                                path='/media/svg/icons/Shopping/Cart3.svg'
                                                className='svg-icon-5 svg-icon'
                                            />		Create Invoice
                                        </Link>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-primary btn-sm" onClick={() => handlePaymentModalShow(salesOrderInfo)}>
                                            <KTSVG
                                                path='/media/svg/icons/Shopping/Cart3.svg'
                                                className='svg-icon-5 svg-icon'
                                            />		Make Payment
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        {salesOrderInfo?.deletePermission &&
                                            <button className="btn btn-primary btn-sm" onClick={() => deleteSalesOrder(idSalesOrders)}>
                                                <KTSVG
                                                    path='/media/svg/icons/General/Trash.svg'
                                                    className='svg-icon-5 svg-icon'
                                                />		Delete
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-flush overflow-hidden m-2 me-0">
                            <div className="card-header py-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label fw-bold text-dark">Terms & Conditions</span>
                                </h3>
                            </div>
                            <div className="card-body pt-0">
                                <Table striped bordered hover size="sm">
                                    <tbody>

                                        {salesOrderInfo?.terms?.length === 0 &&
                                            <tr>
                                                <td colSpan={2}>No terms/conditions added</td>
                                            </tr>
                                        }
                                        {salesOrderInfo?.terms?.map((term, index) => (
                                            <tr>
                                                <td width={'5%'} className='text-center'>{index + 1}</td>
                                                <td width={'95%'} key={index}>{term?.terms_clause}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </Col>
                </Row>
                {salesOrderInfo &&
                    <>
                        <ShipmentCreate show={createShipmentModalShow} handleClose={handleCreateShipmentModalClose} salesOrderInfo={salesOrderInfo} />
                        <EditSr show={editSrModalShow} handleClose={handleEditSrModalClose} salesOrderInfo={salesOrderInfo} />
                        <EditSalesOrderItem show={editItemModalShow} handleClose={handleEditItemModalClose} salesOrderInfo={salesOrderInfo} />
                        <SalesPaymentCreate show={createPaymentModalShow} handleClose={handleCreatePaymentModalClose} salesOrderInfo={salesOrderInfo} />
                    </>
                }
            </Tab>
            <Tab eventKey="shipmentHistory" title="Shipment History">
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Items</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Row>
                            <Col>
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ width: '7%' }}>Shipment ID</th>
                                            <th className='text-center' style={{ width: '7%' }}>Chalan No</th>
                                            <th className='text-center' style={{ width: '7%' }}>Shipment Date</th>
                                            <th className='text-center' style={{ width: '7%' }}>Vehicle No</th>
                                            <th className='text-center' style={{ width: '7%' }}>Driver Name</th>
                                            <th className='text-center' style={{ width: '7%' }}>Driver Phone No</th>
                                            <th style={{ width: '15%' }}>Shipping Address</th>
                                            <th style={{ width: '15%' }}>Product</th>
                                            <th style={{ width: '8%' }}>Packing</th>
                                            <th className='text-center' style={{ width: '7%' }}>Delivered Quantity</th>
                                            <th className='text-center' style={{ width: '7%' }}>Unit Rate</th>
                                            <th className='text-center' style={{ width: '7%' }}>Amount</th>
                                            <th className='text-center' style={{ width: '9%' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesOrderInfo?.shipments?.length === 0 &&
                                            <tr>
                                                <td colSpan={7}>No Data Found</td>
                                            </tr>
                                        }
                                        {salesOrderInfo?.shipments?.map((shipment, index) => (
                                            <>
                                                <tr>
                                                    <td rowSpan={shipment?.rows?.length} className='text-center'>{shipment.id_shipments}</td>
                                                    <td rowSpan={shipment?.rows?.length} className='text-center'>{shipment.chalan_no}</td>
                                                    <td rowSpan={shipment?.rows?.length} className='text-center'>{shipment.shipment_date}</td>
                                                    <td rowSpan={shipment?.rows?.length} className='text-center'>{shipment.vehicle_no}</td>
                                                    <td rowSpan={shipment?.rows?.length} className='text-center'>{shipment.driver_name}</td>
                                                    <td rowSpan={shipment?.rows?.length} className='text-center'>{shipment.driver_phone_no}</td>
                                                    <td rowSpan={shipment?.rows?.length}>{shipment.shipping_address}</td>
                                                    <td>{shipment.rows[0].sales_order_row?.product?.product_name}</td>
                                                    <td>{shipment.rows[0].sales_order_row?.product?.packing}</td>
                                                    <td className='text-center'>{shipment.rows[0].quantity}</td>
                                                    <td className='text-center'>{shipment.rows[0].sales_order_row.unit_rate}</td>
                                                    <td className='text-center'>{numberFormat(parseFloat(shipment.rows[0].quantity) * shipment.rows[0].sales_order_row.unit_rate)}</td>
                                                    <td className='text-center'>
                                                        {salesOrderInfo?.deletePermission &&
                                                            <button className="btn btn-light-danger btn-sm" onClick={() => deleteShipment(shipment.id_shipments)}>
                                                                <KTSVG
                                                                    path='/media/svg/icons/General/Trash.svg'
                                                                    className='svg-icon-5 svg-icon'
                                                                />		Delete
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                                {shipment?.rows?.map((item, k) => (
                                                    <>
                                                        {k !== 0 &&
                                                            <tr>

                                                                <td>{item?.sales_order_row?.product?.product_name}</td>
                                                                <td>{item?.sales_order_row?.product?.packing}</td>
                                                                <td className='text-center'>{item?.quantity}</td>
                                                                <td className='text-center'>{item?.sales_order_row.unit_rate}</td>
                                                                <td className='text-center'>{numberFormat(parseFloat(item?.quantity) * item?.sales_order_row.unit_rate)}</td>
                                                            </tr>
                                                        }
                                                    </>
                                                ))}
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="invoices" title="Invoices">
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Invoices of this Sales Order</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Row>
                            <Col>
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ width: '20%' }}>Invoice #</th>
                                            <th className='text-center' style={{ width: '20%' }}>Invoice ID</th>
                                            <th className='text-center' style={{ width: '20%' }}>Invoice Date</th>
                                            <th className='text-center' style={{ width: '20%' }}>Invoice Amount</th>
                                            <th className='text-center' style={{ width: '20%' }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesOrderInfo?.shipments?.length === 0 &&
                                            <tr>
                                                <td colSpan={7}>No Data Found</td>
                                            </tr>
                                        }
                                        {salesOrderInfo?.invoices?.map((invoice, index) => (

                                            <tr>

                                                <td className='text-center'>{invoice.id_sales_invoice}</td>
                                                <td className='text-center'>{invoice.invoice_id}</td>
                                                <td className='text-center'>{invoice.invoice_date}</td>
                                                <td className='text-center'>{numberFormat(invoice.invoice_amount)}</td>
                                                <td className='text-center'>
                                                    {salesOrderInfo?.deletePermission &&
                                                        <button className="btn btn-light-danger btn-sm" onClick={() => deleteSalesInvoice(invoice.id_sales_invoice)}>
                                                            <KTSVG
                                                                path='/media/svg/icons/General/Trash.svg'
                                                                className='svg-icon-5 svg-icon'
                                                            />		Delete
                                                        </button>
                                                    }
                                                    <Link className="btn btn-light-primary btn-sm" to={`/sales-invoice/${invoice.id_sales_invoice}`}>
                                                        <KTSVG
                                                            path='/media/svg/icons/Layout/Layout-grid.svg'
                                                            className='svg-icon-5 svg-icon'
                                                        />		Details
                                                    </Link>
                                                    <a className="btn btn-light-primary btn-sm" target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_BACKEND}invoiceDownload/${invoice.id_sales_invoice}`}>
                                                        <KTSVG
                                                            path='/media/svg/icons/Files/Downloads-folder.svg'
                                                            className='svg-icon-5 svg-icon'
                                                        />		Download
                                                    </a>
                                                </td>
                                            </tr>

                                        ))}
                                        <tr>
                                            <td colSpan={3} className='text-end'><b>Total Invoiced Amount : </b></td>
                                            <td className='text-center'><b>{numberFormat(salesOrderInfo?.invoices_sum_invoice_amount)}</b></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="payments" title="Payments">
                <div className="card card-flush overflow-hidden m-2 me-0">
                    <div className="card-header py-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold text-dark">Payments of this Sales Order</span>
                        </h3>
                    </div>
                    <div className="card-body pt-0">
                        <Row>
                            <Col>
                                <Table striped bordered hover size='sm'>
                                    <thead>
                                        <tr>
                                            <th className='text-center' style={{ width: '10%' }}>ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Type</th>
                                            <th className='text-center' style={{ width: '10%' }}>Method</th>
                                            <th className='text-center' style={{ width: '10%' }}>Invoice ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Date</th>
                                            <th className='text-center' style={{ width: '10%' }}>Amount</th>
                                            <th className='text-center' style={{ width: '10%' }}>Remarks</th>
                                            <th className='text-center' style={{ width: '10%' }}>Transaction ID</th>
                                            <th className='text-center' style={{ width: '10%' }}>Payment By</th>
                                            <th className='text-center' style={{ width: '10%' }}>Payment Info</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesOrderInfo?.payments?.length === 0 &&
                                            <tr>
                                                <td colSpan={10}>No Data Found</td>
                                            </tr>
                                        }
                                        {salesOrderInfo?.payments?.map((payment, index) => (
                                            <>
                                                <tr>

                                                    <td className='text-center'>{payment.id_sales_payments}</td>
                                                    <td className='text-center'>{payment.payment_type}</td>
                                                    <td className='text-center'>{payment.payment_method}</td>
                                                    <td className='text-center'>{payment.id_sales_invoice}</td>
                                                    <td className='text-center'>{payment.payment_date}</td>
                                                    <td className='text-center'>{numberFormat(payment.payment_amount)}</td>
                                                    <td className='text-center'>{payment.remarks}</td>
                                                    <td className='text-center'>{payment.transaction_id}</td>
                                                    <td className='text-center'>{payment.creator.first_name + ' ' + payment.creator.last_name}</td>
                                                    <td className='text-center'>{payment.paymentInfo}</td>
                                                </tr>
                                            </>
                                        ))}
                                        <tr>
                                            <td colSpan={5} className='text-end'><b>Total Payment Amount : </b></td>
                                            <td className='text-center'><b>{numberFormat(salesOrderInfo?.payments_sum_payment_amount)}</b></td>
                                            <td colSpan={4}></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Tab>


        </Tabs>


    </>
    )
}

export default SaledOrderDetails
