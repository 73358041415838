const numberFormat = (value: any) => {

	value = parseFloat(value)
	if (typeof (value) !== 'number') {
		return value;
	}
	return parseFloat(value.toString()).toLocaleString('en-US', {
		style: 'decimal',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

const capitalize = (str:string) => {
	if (!str || typeof str !== 'string') return ''; // Guard clause for undefined or non-string inputs
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const unitNameByProject = (project: any) => {
	let unit = 'lt';
	if (project === '1') {
		unit = 'lt';
	} else if (project === '2') {
		unit = 'mm';
	}
	return unit;
}


export { numberFormat, capitalize, unitNameByProject};

